<template>
  <div>
    <cs-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :pagination="pagination"
      @filtered="handelFiltering"
      filterable-name="Status"
      filterable-key="status"
      :filterable-list="statuses"
      :show-expand="true"
      class="transactions-table"
    >
      <template #after-header-filterable>
        <b-col class="my-2 my-md-0">
          <div class="d-flex align-items-center">
            <label class="mr-3 mb-0 d-none d-md-block">Cooperative:</label>
            <b-form-select v-model="cooperative_id" :options="withViewAll(cooperatives)" @change="handleFilterableSelect" />
          </div>
        </b-col>
      </template>

      <template #expand="{ item }"> 
        <td :colspan="headers.length + 1" :class="`expand-${item.id}`" class="printed-element">
          <b-row>
            <b-col md="10" class="mx-auto">
              <b-card class="card-custom card-stretch gutter-b" body-class="d-flex flex-column">
                <div>
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="card-title flex-column align-items-start">
                      #{{ item.id }}
                      <p class="text-primary">{{ get(item, 'attributes.paid_date', '-') | moment('LL') }}</p>
                      <p>
                        {{ get(item, 'attributes.applicant.data.attributes.first_name') }}
                        {{ get(item, 'attributes.applicant.data.attributes.last_name') }}
                      </p>
                      <p>
                        {{ get(item, 'attributes.cooperative.data.attributes.company_name') }}
                      </p>
                    </div>
                    <div class="card-toolbar d-flex print-button">
                      <b-btn variant="primary" @click="onPrintExpandElement(item.id)">
                        <i class="flaticon2-print"></i>
                        Print
                      </b-btn>
                    </div>
                  </div>

                  <b-row>
                    <b-col md="8" class="mx-auto">
                      <div class="table-responsive mb-0">
                        <table class="table">
                          <tbody>
                            <tr class="border-bottom-0">
                              <td class="font-weight-bold border-top-0 pl-0 py-4">Payment by Applicant</td>
                              <td class="border-top-0 pr-0 py-4 text-left">
                                <span class="d-block d-md-inline font-weight-bolder text-primary ml-md-10">{{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.full_fee')) }}</span>
                              </td>
                            </tr>
                            <tr class="border-bottom-0">
                              <td class="font-weight-bold border-top-0 pl-0 py-4">App Coordinator</td>
                              <td class="border-top-0 pr-0 py-4 text-left">
                                <span class="d-block d-md-inline font-weight-bolder text-primary ml-md-10">{{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.coordinator_fee')) }}</span>
                              </td>
                            </tr>
                            <tr class="border-bottom-0">
                              <td class="font-weight-bold border-top-0 pl-0 py-4">Stripe Fee</td>
                              <td class="border-top-0 pr-0 py-4 text-left">
                                <span class="d-block d-md-inline font-weight-bolder text-primary ml-md-10"> {{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.stripe_fee')) }} </span>
                              </td>
                            </tr>
                            <tr class="border-bottom-0">
                              <td class="font-weight-bold border-top-0 pl-0 py-4">Fixed Appexpress Fee</td>
                              <td class="border-top-0 pr-0 py-4 text-left">
                                <span class="d-block d-md-inline font-weight-bolder text-primary ml-md-10"
                                  >
                                  {{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.fixed_appexpress_fee')) }}
                                </span>
                              </td>
                            </tr>
                            <tr class="border-bottom-0">
                              <td class="font-weight-bold border-top-0 pl-0 py-4">Background Check Fee</td>
                              <td class="border-top-0 pr-0 py-4 text-left">
                                <span class="d-block d-md-inline font-weight-bolder text-primary ml-md-10">
                                  {{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.background_fee')) }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </td>
      </template>

      <template #[`item.applicant_name`]="{ item }">
        {{ get(item, 'attributes.applicant.data.attributes.first_name') }}
        {{ get(item, 'attributes.applicant.data.attributes.last_name') }}
      </template>

      <template #[`item.date`]="{ item }">
        {{ get(item, 'attributes.paid_date', '-') | moment('LL') }}
      </template>

      <template #[`item.cooperative_name`]="{ item }">
        <span>{{ get(item, 'attributes.cooperative.data.attributes.company_name') }}</span>
      </template>

      <template #[`item.item_id`]="{ item }">
        <code>{{ get(item, 'id') }}</code>
      </template>

      <template #[`item.transaction_amount`]="{ item }">
        <span>{{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.full_fee')) }}</span>
      </template>

      <template #[`item.transaction_status`]="{ item }">
        <span class="label font-weight-bold label-lg label-inline" :class="['label-light-' + transactionStatuses[get(item, 'attributes.status')]]">
          {{ get(item, 'attributes.status') }}
        </span>
      </template>
    </cs-table>
  </div>
</template>

<script>
import PHE from 'print-html-element';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'cooperatives-index',
  data() {
    return {
      statuses: [
        { text: 'Created', value: 'Created' },
        { text: 'Canceled', value: 'Canceled' },
        { text: 'Succeeded', value: 'Succeeded' },
        { text: 'Payment Failed', value: 'Payment Failed' },
        { text: 'Attached', value: 'Attached' },
      ],
      headers: [
        {
          text: 'Name',
          value: 'applicant_name',
        },
        {
          text: 'Date',
          value: 'date',
          align: 'center',
        },
        {
          text: 'Cooperative Name',
          value: 'cooperative_name',
          align: 'center',
        },
        {
          text: 'Transaction Id',
          value: 'item_id',
          align: 'center',
        },
        {
          text: 'Transaction Amount',
          value: 'transaction_amount',
          align: 'center',
        },
        {
          text: 'Transaction Status',
          value: 'transaction_status',
          align: 'center',
        },
      ],
      loading: false,
      cooperative_id: null,
    };
  },
  computed: {
    ...mapGetters('transactions', {
      items: 'items',
      pagination: 'pagination',
    }),
    ...mapGetters('resources', {
      cooperatives: 'cooperatives',
    }),
  },
  async mounted() {
    await this.handleItems();
    await this.getCooperatives();
  },
  methods: {
    ...mapActions({
      getItems: 'transactions/index',
      getCooperatives: 'resources/cooperatives',
    }),
    onPrintExpandElement(id) {
      PHE.printElement(document.querySelector(`.expand-${id}`));
    },
    async handelFiltering(filters) {
      const routeQuery = this.$route.query;

      if (this.cooperative_id) {
        filters.cooperative_id = this.cooperative_id;
      }

      const isSame = this.isSame(routeQuery, filters);

      if (isSame) return;

      await this.$router.replace({ query: filters });

      await this.handleItems();
    },
    withViewAll(data) {
      return [{ text: 'View All', value: null }, ...data];
    },
    async handleFilterableSelect(cooperative_id) {
      const routeQuery = this.$route.query;

      const newRouteQuery = Object.assign({}, routeQuery, { cooperative_id, page: 1 });

      if (!cooperative_id) {
        delete newRouteQuery.cooperative_id;
      }

      const isSame = this.isSame(routeQuery, newRouteQuery);

      if (isSame) return;

      await this.$router.replace({ query: newRouteQuery });

      await this.handleItems();
    },
    handleItems: debounce(async function(e) {
      this.loading = true;
      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;
      this.cooperative_id = query.cooperative_id || null;

      if (query.status != undefined) query.where = { status: query.status };

      if (query.cooperative_id !== undefined) {
        query.where = query.where || {};
        query.where.cooperative_id = query.cooperative_id;
      }

      if (query.per == undefined) query.per = 10;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.getItems(query);

      this.loading = false;
    }, 400),
  },
};
</script>
<style lang="scss">
.transactions-table {
  table {
    .v-data-table__expanded {
      box-shadow: unset !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.transactions-table {
  ::v-deep .card-header {
    justify-content: flex-start;
  }
}
@media print {
  .print-button {
    display: none !important;
    height: 0;
  }
  .printed-element {
    color: black !important;
  }
}
</style>
